import React, { useEffect, useState } from "react";
import { Chip, Container } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

export function Configuration(props: { onConfirm?: () => void }) {
  const { onConfirm } = props;
  const [users, setUsers] = useState<string[]>(
    localStorage["users"]?.split("|") ??
      process.env.REACT_APP_DEFAULT_PLAYERS?.split("|") ??
      []
  );

  return (
    <>
      <Typography variant="h5" component="div" gutterBottom>
        Configure streamers
      </Typography>
      <Stack direction="column" spacing={1}>
        <Typography variant="caption" component="div" gutterBottom>
          Remove streamers you don't want to watch (aka: yourself)
        </Typography>
        <Stack direction="row" spacing={1}>
          {users.map((u) => (
            <Chip
              variant="outlined"
              onDelete={() => {
                setUsers(users.filter((user) => user !== u));
              }}
              label={u}
            />
          ))}
        </Stack>
        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            onClick={() => {
              localStorage.setItem("users", users.join("|"));
              if (onConfirm) {
                onConfirm();
              }
            }}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              setUsers(process.env.REACT_APP_DEFAULT_PLAYERS?.split("|") ?? []);
            }}
          >
            Restore Defaults
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
