import React from "react";
import PlayersDropdown from "./PlayersDropdown";
import { FutbinPlayer } from "../../models/FutbinPlayer";
import { PlayerInfo } from "../PlayerInfo";

export default function FutbinPlayersDropdown(props: {
  onSelect: (selectedCard: FutbinPlayer) => any;
}) {
  return (
    <PlayersDropdown<FutbinPlayer>
      getLabel={(p) => p.full_name}
      resolveSuggestion={async (term) => {
        const response = await fetch(
          "https://www.futbin.com/search?year=22&extra=1&v=1&term=" + term
        );
        const suggestions = await response.json();
        return suggestions;
      }}
      renderSuggestion={(suggestion) => <PlayerInfo {...suggestion} />}
      onSelect={props.onSelect}
    />
  );
}
