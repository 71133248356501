import React, { useState, useEffect } from "react";
import { TextField, MenuItem, Paper } from "@material-ui/core";
import Downshift from "downshift";
import { useDebounce } from "../../hooks/useDebounce";

export default function PlayersDropdown<T>(props: {
  onSelect: (selectedCard: T) => any;
  resolveSuggestion: (term: string) => Promise<T[]>;
  renderSuggestion: (suggestion: T) => JSX.Element;
  getLabel: (suggestion: T) => string;
}) {
  const { onSelect, resolveSuggestion, renderSuggestion, getLabel } = props;
  const [value, setValue] = useState<string>("");
  const [suggestions, setSuggestions] = useState<T[]>([]);
  const searchText = useDebounce(value);

  useEffect(() => {
    if (searchText.length < 3) {
      return;
    }
    const term = searchText.toLocaleLowerCase();

    resolveSuggestion(term).then((s) => {
      setSuggestions(s);
    });
  }, [searchText]);

  function renderInput(inputProps: any) {
    const { InputProps, ref, ...other } = inputProps;

    return (
      <TextField
        InputProps={{
          inputRef: ref,
          ...InputProps,
        }}
        {...other}
      />
    );
  }

  return (
    <Downshift
      id="downshift-simple"
      onInputValueChange={(v) => {
        setValue(v);
      }}
      onChange={(e, h) => {
        if (e) {
          onSelect(e);
          h.clearSelection();
        }
      }}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        highlightedIndex,
        isOpen,
      }) => (
        <div>
          {renderInput({
            fullWidth: true,
            InputProps: getInputProps({
              placeholder: "Search a player",
            }),
          })}
          <div {...getMenuProps()}>
            {isOpen ? (
              <Paper square>
                {suggestions.map((suggestion, index) => (
                  <MenuItem
                    {...getItemProps({
                      item: suggestion,
                      label: getLabel(suggestion),
                    })}
                    key={index}
                    selected={highlightedIndex === index}
                  >
                    {renderSuggestion(suggestion)}
                  </MenuItem>
                ))}
              </Paper>
            ) : null}
          </div>
        </div>
      )}
    </Downshift>
  );
}
