import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import "./pversion2.css";
import { FutbinPlayer } from "../../models/FutbinPlayer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      textAlign: "left",
      paddingTop: 5,
      height: 40,
      fontWeight: "normal",
      display: "block",
      fontFamily: 'Din Pro", "Segoe UI", "Segoe WP", Tahoma, Arial, sans-serif',
    },
    club: {
      width: 20,
      verticalAlign: "middle",
    },

    country: {
      width: 20,
      verticalAlign: "middle",
    },
    image: {
      width: 38,
      position: "relative",
      top: "-2px",
      verticalAlign: "middle",
    },
    name: {
      position: "relative",
      top: "-2px",
      fontSize: 14,
      fontWeight: 600,
      fontFamily: "Poppins",

      whiteSpace: "nowrap",
      overflow: "hidden",
      verticalAlign: "middle",
      textOverflow: "ellipsis",
      display: "inline-block",
    },
    position: {
      fontSize: 10,
      color: "#a0a1a1",
      paddingLeft: 2,
      position: "relative",
      top: "-2px",
    },

    rating: {
      float: "right",
      padding: 4,
      margin: "0 2px 0 0",
      borderRadius: 2,
    },
  })
);

const rarityMap = {};
rarityMap["1"] = "rare";
rarityMap["3"] = "if";
rarityMap["4"] = "purple";
rarityMap["5"] = "toty";
rarityMap["6"] = "bluered";
rarityMap["8"] = "motm";
rarityMap["11"] = "tots_moments";
rarityMap["12"] = "icon";
rarityMap["21"] = "otw";
rarityMap["22"] = "halloween";
rarityMap["30"] = "fut-bd";
rarityMap["32"] = "futmas";
rarityMap["43"] = "potm_epl";
rarityMap["48"] = "ucl_rare";
rarityMap["49"] = "ucl_motm";
rarityMap["64"] = "toty_nominee";
rarityMap["66"] = "tots";
rarityMap["70"] = "ucl_tott";
rarityMap["71"] = "future_stars";
rarityMap["72"] = "heroes";
rarityMap["84"] = "icon_moment";
rarityMap["95"] = "headliners";
rarityMap["107"] = "adidas";
rarityMap["110"] = "signature_signings";
rarityMap["118"] = "winter_wildcards";

function getCardColor(rating: number): string {
  if (rating >= 75) {
    return "gold";
  } else if (rating >= 65) {
    return "silver";
  }
  return "bronze";
}

function extractId(url: string) {
  var myRegexp = /.*\/(\d+)\.png/g;
  var match = myRegexp.exec(url);
  return match ? match[1] : "";
}

export function PlayerInfo(props: FutbinPlayer) {
  const classes = useStyles({});
  const clubImage = `https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/clubs/normal/${extractId(
    props.club_image
  )}.png`;
  const nationImage = `https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/flags/card/${extractId(
    props.nation_image
  )}.png`;
  const playerImage = `https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/portraits/${extractId(
    props.image
  )}.png`;
  return (
    <div className={classes.root}>
      <span
        className={`rating ut22 ${getCardColor(parseInt(props.rating))} ${
          rarityMap[props.rare_type]
        }`}
      >
        {props.rating}
      </span>
      <img className={classes.club} src={clubImage} alt="" />
      <img className={classes.country} src={nationImage} alt="" />
      <img className={classes.image} src={playerImage} alt="" />
      <span className={classes.name}>{props.full_name}</span>
      <span className={classes.position}>({props.position})</span>
    </div>
  );
}
