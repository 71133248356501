import React, { useEffect, useState } from "react";

export function PlayerPrice(props: {
  id: string | number;
  onPriceFetched?: (price: number) => void;
}) {
  const [loading, setLoading] = useState(true);
  const [price, setPrice] = useState(0);
  useEffect(() => {
    if (!loading) {
      return;
    }

    fetch(`https://www.futbin.com/22/getTp?pid=${props.id}&type=player`)
      .then((r) => r.json())
      .then((r) => {
        const price = r.data.p[0];
        setPrice(price);
        if (props.onPriceFetched) {
          props.onPriceFetched(price);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  });
  return <div>{loading ? <span>loading...</span> : <span>{price}</span>}</div>;
}
