import React, { useEffect, useState } from "react";
import { Configuration } from "../Configuration";

export function MultiTwitchBuilder() {
  const [loading, setLoading] = useState<boolean>(true);
  const [isConfigured, setIsConfigured] = useState<boolean>(
    localStorage["users"]
  );
  const [users, setUsers] = useState<string[]>([]);
  const [connectedUsers, setConnectedUsers] = useState<string[]>();
  const [url, setUrl] = useState<string>();
  const [mtUrl, setMtUrl] = useState<string>("https://www.multitwitch.tv/");

  useEffect(() => {
    setUsers(localStorage["users"]?.split("|") ?? []);
  }, [isConfigured]);

  useEffect(() => {
    if (!users.length) {
      return;
    }

    setUrl(
      "https://api.twitch.tv/helix/streams?" +
        users.map((u) => `user_login=${u}`).join("&")
    );
  }, [users]);

  useEffect(() => {
    if (!users.length || !url) {
      setConnectedUsers([]);
      setLoading(false);
      return;
    }

    fetch(
      "https://id.twitch.tv/oauth2/token?client_id=ufnjzit6uvrrqq9uq6kjdrgwojkmad&grant_type=client_credentials&client_secret=u3rlszu3eh4ucyiutt12r9ixyrndzp",
      { method: "POST" }
    )
      .then((response) => response.json())
      .then((json1) => {
        return {
          method: "GET",
          headers: {
            Accept: "application/vnd.twitchtv.v5+json",
            "Client-ID": "ufnjzit6uvrrqq9uq6kjdrgwojkmad",
            Authorization: `Bearer ${json1.access_token}`,
          },
        };
      })
      .then((requestInit) => fetch(url, requestInit))
      .then((response) => response.json())
      .then((json) => {
        const cUsers = json.data.map((d) => d.user_login);
        setConnectedUsers(cUsers);
        setMtUrl("https://www.multitwitch.tv/" + cUsers.join("/"));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url]);

  useEffect(() => {
    if (connectedUsers?.length) {
      // eslint-disable-next-line no-restricted-globals
      location.replace(mtUrl);
    }
  }, [mtUrl]);

  if (!isConfigured) {
    return <Configuration onConfirm={() => setIsConfigured(true)} />;
  }

  return (
    <>
      {loading && <div>Building the Multi-Twitch...</div>}
      {connectedUsers?.length === 0 && <div>No one is connected</div>}
    </>
  );
}
