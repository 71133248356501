import React, { useState } from "react";
import {
  Theme,
  InputAdornment,
  InputLabel,
  IconButton,
  InputBase,
  Divider,
  Paper,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import coinsImage from "../../assets/images/coins.png";
import { PlusIcon, MinusIcon } from "../../assets/icons";
import useLongPress from "../../hooks/useLongPress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      padding: "2px 4px",
      alignItems: "center",
    },
    margin: {
      marginTop: "8",
      marginBottom: "8",
      width: "100%",
    },
    input: {
      marginLeft: 8,
      flex: 1,
      fontFamily: "Cairo",
      color: "#3b97d3",
      fontWeight: 700,
    },
    iconButton: {
      padding: 10,
    },
    img: {
      width: 11,
      position: "relative",
      top: 0,
      marginLeft: 3,
      content: "url(" + coinsImage + ")",
    },
    divider: {
      width: 1,
      height: 28,
      margin: 4,
    },
  })
);

export function InputCoins(props: {
  initialValue?: number;
  label?: string;
  placeholder?: string;
  name?: string;
  help?: string;
  onChanged?: (newValue: number) => void;
}) {
  const { initialValue, onChanged, label, placeholder, name } = props;
  const classes = useStyles({});
  const [value, setValue] = useState<number>(initialValue ? initialValue : 150);
  const [focussed, setFocussed] = useState(false);
  const whileDecrementing = useLongPress(
    () => {
      decrement();
    },
    200,
    true
  );
  const whileIncrementing = useLongPress(
    () => {
      increment();
    },
    200,
    true
  );
  const [rawValue, setRawValue] = useState<string>(
    initialValue && initialValue > 150 ? initialValue.toString() : ""
  );

  function rebase(v: number): number {
    if (v < 1000) return Math.ceil(v / 50) * 50;

    if (v < 10000) return Math.ceil(v / 100) * 100;

    if (v < 50000) return Math.ceil(v / 250) * 250;

    if (v < 100000) return Math.ceil(v / 500) * 500;

    return Math.ceil(v / 1000) * 1000;
  }

  function updateValue(v: number) {
    if (v < 150) {
      v = 150;
    }
    setValue(v);
    setRawValue(v > 150 ? v.toLocaleString("en") : "");
    if (onChanged) {
      onChanged(v);
    }
  }

  function getIncrement(): number {
    if (value < 1000) return 50;
    if (value < 10000) return 100;
    if (value < 50000) return 250;
    if (value < 100000) return 500;
    return 1000;
  }

  function getDecrement(): number {
    if (value <= 1000) return 50;
    if (value <= 10000) return 100;
    if (value <= 50000) return 250;
    if (value <= 100000) return 500;
    return 1000;
  }

  function increment() {
    updateValue(value + getIncrement());
  }

  function decrement() {
    updateValue(value - getDecrement());
  }

  return (
    <div className={classes.margin}>
      <InputLabel
        htmlFor="adornment-weight"
        focused={focussed}
        shrink={focussed}
      >
        {label}
      </InputLabel>
      <Paper className={classes.root}>
        <IconButton
          className={classes.iconButton}
          size="small"
          onClick={() => {
            decrement();
          }}
          {...whileDecrementing}
        >
          <MinusIcon />
        </IconButton>
        <Divider className={classes.divider} />
        <InputBase
          className={classes.input}
          id="adornment-weightt"
          placeholder={placeholder}
          inputProps={{ style: { textAlign: "right" } }}
          name={name}
          fullWidth
          value={rawValue}
          onFocus={() => {
            setFocussed(true);
            setRawValue(value > 150 ? value.toString() : "");
          }}
          onBlur={() => {
            setFocussed(false);
            let newVal = parseInt(rawValue);
            if (Number.isNaN(newVal)) {
              newVal = 0;
            }
            newVal = rebase(newVal);
            updateValue(newVal);
          }}
          onChange={(e) => {
            setRawValue(e.target.value);
          }}
          endAdornment={
            <InputAdornment position="end">
              <img className={classes.img} />
            </InputAdornment>
          }
        />
        <Divider className={classes.divider} />
        <IconButton
          className={classes.iconButton}
          size="small"
          onClick={() => {
            increment();
          }}
          {...whileIncrementing}
        >
          <PlusIcon />
        </IconButton>
      </Paper>
    </div>
  );
}
