import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Paper,
  TextField,
  Grid,
  Button,
} from "@material-ui/core";
import { FutbinPlayersDropdown } from "../../components/PlayersDropdown";
import { FutbinPlayer } from "../../models/FutbinPlayer";
import { PlayerPrice } from "../../components/PlayerPrice";
import { PlayerInfo } from "../../components/PlayerInfo";
import { InputCoins } from "../../components/InputCoins";
import { DeleteIcon } from "../../assets/icons";
import { ChemistryStylePicker } from "../../components/ChemistryStylePicker";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

interface CustomFilter {
  id: number;
  player: FutbinPlayer;
  chemistry?: string;
  price?: number;
}

export function FiltersCreator(props: {}) {
  const [filterName, setFilterName] = useState<string>("");
  const [filters, setFilters] = useState<CustomFilter[]>([]);
  const mixedFilters: ExeSniperMixedFilters = {
    name: filterName,
    filters: filters.map((f) => ToExeSniperFilter(f)),
  };

  const code = `// press F12 and copy-paste this in your console
const filters = JSON.parse(localStorage.exeSniperMixed);
filters.push(${JSON.stringify(mixedFilters)});
localStorage.exeSniperMixed = JSON.stringify(filters);
location.reload();`;
  return (
    <div>
      <TextField
        required
        id="outlined-required"
        value={filterName}
        label="Filter's name"
        onChange={(e) => {
          setFilterName(e.target.value);
        }}
      />
      <div style={{ padding: 10 }} />
      <Grid container spacing={2}>
        <Grid item md={12} lg={8}>
          <FiltersTable
            onChange={(f) => {
              setFilters(f);
            }}
          />
        </Grid>
        <Grid item md={12} lg={4}>
          <Button
            variant="contained"
            onClick={() => {
              navigator.clipboard.writeText(code);
            }}
          >
            Copy to clipboad
          </Button>
          <SyntaxHighlighter wrapLongLines language="javascript" style={docco}>
            {code}
          </SyntaxHighlighter>
        </Grid>
      </Grid>
    </div>
  );
}

export function FiltersTable(props: {
  onChange?: (filters: CustomFilter[]) => void;
}) {
  const [rowId, setRowId] = useState(0);
  const [rows, setRows] = React.useState<CustomFilter[]>([]);

  function update(filters: CustomFilter[]) {
    console.log(filters);
    setRows(filters);
    if (props.onChange) {
      props.onChange(filters);
    }
  }

  return (
    <div className="App">
      <FutbinPlayersDropdown
        onSelect={(p) => {
          setRowId(rowId + 1);
          update([
            ...rows,
            {
              id: rowId,
              player: p,
              chemistry: "",
            },
          ]);
        }}
      />
      <Paper>
        <Table aria-label="caption table">
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell align="left">
                  <PlayerInfo {...row.player} />
                </TableCell>
                <TableCell align="left">
                  <ChemistryStylePicker
                    value={row.chemistry}
                    onChange={updateChemistry(row)}
                  />
                </TableCell>
                {/* <TableCell align="left">
                  {row.player.version} ({row.player.rare_type})
                </TableCell> */}
                <TableCell width="30%" align="left">
                  {row.price ? (
                    <InputCoins
                      initialValue={row.price}
                      onChanged={updatePrice(row)}
                    />
                  ) : (
                    <PlayerPrice
                      id={row.player.id}
                      onPriceFetched={updatePrice(row)}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => {
                      update(rows.filter((r) => r.id !== row.id));
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );

  function updateChemistry(
    row: any
  ): ((chemistry: string) => void) | undefined {
    return (c) => {
      update(rows.map((r) => (r.id === row.id ? { ...r, chemistry: c } : r)));
    };
  }

  function updatePrice(row: any): ((price: number) => void) | undefined {
    return (p) => {
      update(rows.map((r) => (r.id === row.id ? { ...r, price: p } : r)));
    };
  }
}

interface ExeSniperMixedFilters {
  name: string;
  filters: ExeSniperFilter[];
}

interface ExeSniperFilter {
  type: string; // "player"
  criteria: Criteria;
  options: Options;
}

interface Criteria {
  filters: Filters;
  prices: Prices;
}

interface Filters {
  field1: string; // gold, silver, special
  field2: string; // ?
  field3: string; // position
  field4: string; // chemistry style
  field5: string; // nationality
  field6: string; // league
  field7: string; // club
  itemName?: string;
  rating?: number;
}

interface Options {
  required: { current: string; max: string };
  changeMin: ChangeMin;
}

export interface ChangeMin {
  from: string | number;
  to: string | number;
}

interface Prices {
  minBid: string;
  maxBid: string;
  minBin: string;
  maxBin: string;
}

function ToExeSniperFilter(f: CustomFilter): ExeSniperFilter {
  return {
    type: "player",
    criteria: {
      filters: {
        field1: "Quality",
        field2: "Rarity",
        field3: "Position",
        field4:
          f.chemistry && f.chemistry !== "" ? f.chemistry : "Chemistry Style",
        field5: "Nationality",
        field6: "League",
        field7: "Club",
        itemName: f.player.full_name,
        rating: parseInt(f.player.rating),
      },
      prices: {
        minBid: "0",
        maxBid: "0",
        minBin: "0",
        maxBin: f.price ? f.price.toString() : "0",
      },
    },
    options: {
      required: { current: "0", max: "10" },
      changeMin: { from: "0", to: "500" },
    },
  };
}
