import React from "react";
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";

const chemistyStyles = [
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/250.png",
    name: "BASIC",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/251.png",
    name: "SNIPER",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/252.png",
    name: "FINISHER",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/253.png",
    name: "DEADEYE",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/254.png",
    name: "MARKSMAN",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/255.png",
    name: "HAWK",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/256.png",
    name: "ARTIST",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/257.png",
    name: "ARCHITECT",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/258.png",
    name: "POWERHOUSE",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/259.png",
    name: "MAESTRO",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/260.png",
    name: "ENGINE",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/261.png",
    name: "SENTINEL",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/262.png",
    name: "GUARDIAN",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/263.png",
    name: "GLADIATOR",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/264.png",
    name: "BACKBONE",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/265.png",
    name: "ANCHOR",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/266.png",
    name: "HUNTER",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/267.png",
    name: "CATALYST",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/268.png",
    name: "SHADOW",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/269.png",
    name: "WALL",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/270.png",
    name: "SHIELD",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/271.png",
    name: "CAT",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/272.png",
    name: "GLOVE",
  },
  {
    icon: "https://www.ea.com/fifa/ultimate-team/web-app/content/22747632-e3df-4904-b3f6-bb0035736505/2022/fut/items/images/mobile/chemistrystyles/list/273.png",
    name: "GK BASIC",
  },
];

export function ChemistryStylePicker(props: {
  value?: string;
  onChange?: (value: string) => void;
}) {
  const [value, setValue] = React.useState(props.value);

  const handleChange = (event) => {
    const v = event.target.value;
    setValue(v);
    if (props.onChange) {
      props.onChange(v);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel>Chemistry</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label="Chemistry"
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>ANY</em>
        </MenuItem>
        {chemistyStyles.map((cs) => (
          <MenuItem value={cs.name}>{cs.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
