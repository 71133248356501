import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Configuration } from "./pages/Configuration";
import { FiltersCreator } from "./pages/FilterCreator";
import { MultiTwitchBuilder } from "./pages/Twitch";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/twitch/configure"
          element={
            <Configuration
              onConfirm={() => {
                window.location.replace("/twitch");
              }}
            />
          }
        />
        <Route path="/filter-builder" element={<FiltersCreator />} />
        <Route path="/twitch" element={<MultiTwitchBuilder />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
