import { Player } from '../models/Player';
import playerDatabase from './players.json'

interface PlayerDatabase {
    LegendsPlayers: Player[];
    Players: Player[];
}
const db = playerDatabase as PlayerDatabase;
export const allPlayers = [...db.LegendsPlayers, ...db.Players];

export function DisplayFullName(p : Player) : string{
    return p.c ?? `${p.f} ${p.l}`;
}